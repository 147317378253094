/* eslint-disable @next/next/no-html-link-for-pages */
import React, { useState } from 'react';

import { inject, observer } from 'mobx-react'
import Image from 'next/legacy/image'

import { THEME } from '@constants/index';
import { Button } from '@element/index'
import defaultAvatar from '@images/avata.webp';
import useClient from '@lib/hooks/useClient';
import { useIsomorphicLayoutEffect } from '@lib/hooks/useIsomorphicLayoutEffect'
import { getRate } from '@utils/getRate'

import { KikiLogo, MenuDrawerSwitch } from '../Navbar/component'
import PersonDrawer from './component/PersonDrawer'
import SearchDrawer from './component/SearchDrawer';
import { SEARCH_IMG } from './const'

import NavBarStyles from './navbar.module.less';

export default inject(rootStore => ({
  userStore: rootStore.store.userStore,
  tradeStore: rootStore.store.tradeStore,
  marketStore: rootStore.store.marketStore
}))(
  observer(props => {
    const {
      userStore: { socialUserInfo: { avatar }, isLogin: initisLogin },
      // postActiveList,
      guideist,
      topSearchType,
      theme = THEME.HIGHLIGHT
    } = props
    const [visible, setVisible] = useState(false);
    const [drawerVisible, setdrawerVisible] = useState(false);
    const [isLogin, setisLogin] = useState(false);
    const isPc = useClient()

    const [peosonvisible, setpeosonvisible] = useState(false);

    useIsomorphicLayoutEffect(() => {
      setisLogin(initisLogin)
    }, [initisLogin])

    const showPersonDrawer = () => {
      setpeosonvisible(true)
    };

    const searchDrawerVisabledChange = () => {
      setdrawerVisible(!drawerVisible)
    }

    const initBaseValue = async () => {
      await props.tradeStore.fetchMain()
      const marketSymbols = await props.marketStore.fetchMarketSymbol()
      props.tradeStore.updatePropertyDate({ marketSymbols, rate: getRate(), coinDetail: props.marketStore.coinDetail })
    }

    useIsomorphicLayoutEffect(() => {
      if (isLogin) {
        initBaseValue()
      }
    }, [isLogin])

    useIsomorphicLayoutEffect(() => {
      setVisible(false);
      setpeosonvisible(false)
      setdrawerVisible(false)
    }, [topSearchType])
    return <>
      <div
        className={`${NavBarStyles.headerH5} ${NavBarStyles.header}`}
        data-name={'kiki-navabar-h5'}
      >
        {
          !isPc && <KikiLogo theme={theme} width='73px' height='26px' />
        }
        <div className={NavBarStyles.headerRight}>
          <Button className={NavBarStyles.menuSearch} onClick={searchDrawerVisabledChange}>
            <Image
              src={SEARCH_IMG?.[theme]}
              layout="fixed"
              width={24}
              height={24}
              className='image_borderRadius_24'
              alt=''
            />
          </Button>
          {isLogin
            ? (<Button className={NavBarStyles.menuAvatar} onClick={showPersonDrawer}>
              <Image
                src={avatar || defaultAvatar}
                layout="fixed"
                width={24}
                height={24}
                className='image_borderRadius_24'
                alt=''
              />
            </Button>)
            : null
          }
          <MenuDrawerSwitch
            visible={visible}
            setVisible={setVisible}
            guideist={guideist}
            theme={theme}
          />

          <SearchDrawer
            visible={drawerVisible}
            onClose={searchDrawerVisabledChange}
            topSearchType={topSearchType}
          />

          <PersonDrawer
            peosonvisible={peosonvisible}
            setpeosonvisible={setpeosonvisible}
          />

        </div>

      </div>
    </>;
  })
)
